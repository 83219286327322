
// Colors
$colors: (
    'white'        : #ffffff,
    'yellow'       : #E3CA0A,
    'green'        : #B2CA06,
    'green-light'  : #F4F6DE,
    'red'          : #EB5E65,

    'petrol'       : #0090A1,
    'black'        : #222222,

    'grey-dark'    : #393939,
    'grey-mid'     : #757575,
    'grey-link'    : #799189,
    'grey-midlight': #9C9C9C,
    'grey-light'   : #E1E1DE,    
    'grey-white'   : #F8F8F6
    

);


// A map of breakpoints.
$breakpoints_width: ( 
    mobile-xs   : 339px,
    mobile      : 768px,
    tablet      : 968px,
    desktop     : 1035px
);


//container sizes
$containersWidth: ( 
    container-wide : 1160px,
    container : 960px,
    container-800: 800px,
    container-narrow: 640px
);


// setup paddings;
$layout-unit: 0.5rem;
$layoutSizes: ( 
    0 : $layout-unit * 0,
    1 : $layout-unit * 1,
    2 : $layout-unit * 2,
    3 : $layout-unit * 3,
    4 : $layout-unit * 4,
    5 : $layout-unit * 5,
    6 : $layout-unit * 6,
    7 : $layout-unit * 7,
    8 : $layout-unit * 8,
    9 : $layout-unit * 9,
    10 : $layout-unit * 10,

    10px : 10px,
);

$elementWidths:  (0, 100); 
$elementHeights: (0, 100); 

//container sizes
$borderRadius: (
    4 : 4px
);


//container sizes
$textSizes: ( 
    sm  : 0.75rem,  //12 
    reg : 1rem,     //16
    plus: 1.25rem,  //20
    md  : 1.5rem,   //24
    lg  : 2rem,     //32
    xl  : 2.75rem,  //44
);


$nebenanHeaderOverlap: 280px;
$nebenanHeaderOverlapMobile: 140px;