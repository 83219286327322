
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

html {
    padding: 0;
    margin: 0; }

body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    padding: 0;
    margin: 0;
    color: map-get($colors, 'grey-dark'); }

a {
    font-weight: 700;
    color: map-get($colors,'grey-link');
    text-decoration: none;
    &:hover {
        color: lighten(map-get($colors,'grey-link'),25%); } }


.bg-green-gradient {}
//    background: linear-gradient(0deg, rgba(147,159,37,1) 0%, rgba(185,202,40,1) 25%, rgba(185,202,40,1) 75%, rgba(160,174,37,1) 100%);
//  background: linear-gradient(180deg, rgba(160,180,6,1) 0%, rgba(178,202,6,1) 30%, rgba(178,202,6,1) 60%, rgba(160,180,6,1) 100%);

.bg-trans-grad {
    background-image: linear-gradient(180deg, rgba(57,57,57,0.30) 0%, rgba(57,57,57,0.00) 100%); }
