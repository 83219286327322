
@import 'vars';

*, *::before, *::after {
    box-sizing: border-box; }

h1,h2,h3,p,ol,ul {
    margin: 0px;
    padding: 0px;
    font-weight: normal;
    line-height: normal; }

ul {
    list-style: none; }

button {
    background-color: transparent;
    border: 0px transparent;
    padding: 0px;
    outline: none;
    font-size: 1rem; }

@function calculateRem($size) {
    $remSize: $size / 16px;
    @return #{$remSize}rem; }


@mixin below-wi($breakpoint) {
    @if map-has-key($breakpoints_width, $breakpoint) {
        $breakpoint-value: map-get($breakpoints_width, $breakpoint);
        @media (max-width: ($breakpoint-value - 1)) {
            @content; } }
    @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.'; } }


@import 'utils/cssvars';
@import 'utils/background';
@import 'utils/display';
@import 'utils/shadow';
@import 'utils/flex';
@import 'utils/layout';
@import 'utils/text';
@import 'utils/borders';

@import 'nebenan';
@import 'nebenan_text';
@import 'nebenan_header';
@import 'nebenan_footer';
@import 'nebenan_photostack';
@import 'nebenan_section';
@import 'nebenan_featurebox';
@import 'nebenan_foldout';
@import 'nebenan_form';
@import 'nebenan_buttons';
@import 'nebenan_logogrid';
@import 'nebenan_cards';

