
.flex-y {
    flex-direction: column;
    display: flex;
    &.flex-y-center {
        justify-content: center; }
    &.flex-x-center {
        align-items: center; }
    &.m-flex-x-center {
        @include below-wi(mobile) {
            align-items: center; } } }

.flex-x,.flex-row {
    display: flex;
    flex-direction: row;
    &.flex-y-center {
        align-items: center; }
    &.flex-x-center {
        justify-content: center; } }

.m-flex-y-reverse {
     @include below-wi(mobile) {
        flex-direction: column-reverse; } }

.flex-1 {
    flex: 1; }

.flex-wrap {
    flex-wrap: wrap; }

.m-flex-wrap {
    @include below-wi(mobile) {
        flex-wrap: wrap; } }

.m-flex-wrap-reverse {
    @include below-wi(mobile) {
        flex-wrap: wrap-reverse; } }

.align-items-center {
    align-items: center; }


@for $i from 1 through 4 {
    .flex-x.col-#{$i} {
        >* {
            width: (100% / $i); } }
    @include below-wi(mobile) {
        .flex-x.col-#{$i} {
            flex-wrap: wrap;
            >* {
                width: 100%; } } } }


// .flex-x.col-3 >*
//         width: (100% / 3);
// .flex-x.col-4 >*
//         width: (100% / 4);

// @include below-wi(mobile)

//     .flex-x[class*=" col-"]
//         flex-wrap: wrap;
//             >*
//                 width: 100%;

