
.na-h1 {
    font-weight: 600;
    font-size: 2.75rem;
    line-height: 120%;
    @include below-wi(mobile) {
        font-size: 2rem;
        line-height: 2.5rem; } }

.na-h2 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 120%; }

.na-h3 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 120%; }


.text-body {
    font-size: 1rem;
    color: map-get($colors, 'grey-mid');

    h1,h2,h3,h4 {
        color: map-get($colors,'black');
        padding-bottom: 1rem; }

    b {
        font-weight: 600;
        color: map-get($colors,'black'); }

    a {
        font-weight: 700;
        color: map-get($colors,'grey-link');
        text-decoration: none;

        &:hover {
            color: lighten(map-get($colors,'grey-link'),25%); } }

    p {
        padding-bottom: 1.75rem; } }


