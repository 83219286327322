

.inline-block {
    display: inline-block; }

.block {
    display: block; }

.mobile-show {
    display: none; }



@include below-wi(mobile) {
    .m-inline-block {
        display: inline-block; }
    .m-block {
        display: block; }
    .mobile-show {
        display: block; }
    .mobile-hide {
        display: none !important; } }


.overflow-x-hidden {
    overflow-x: hidden; }

.no-box-sizing {
    box-sizing: content-box; }



.absolute {
    position: absolute; }
.relative {
    position: relative; }
