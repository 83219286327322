.photostack {
    padding-bottom: 40px;
    &:hover {
        .img-front {
            transform: rotate(1deg); }
        .img-back {
            transform: rotate(-3deg); } }

    .img-back {
        transform: rotate(-2deg);
        transition: transform 0.5s; }

    .img-front {
        transition: transform 0.5s;
        background-color: map-get($colors,'grey'); }

    .photoframe {
        width: 100%;
        height: auto;
        background-color: #666;
        border-radius: 4px;
        border: 10px solid #fff;
        box-sizing: border-box;
        background-size: cover;
        background-position: center center;
        max-width: 90%;
        img {
            width: 100%;
            height: auto; } } }

// .photoframe
//     width: 580px;
//     height: auto;
//     background-color: #666;
//     border-radius: 10px;
//     border: 10px solid #fff;
//     box-sizing: border-box;
//     background-size: cover
//     background-position: center center;
//     max-width: 90%;
//     img
//         width: 100%;
//         height: auto;


// .photo-wrap
//     &:hover
//         .rot-1
//             transform: rotate(-3deg)
//         .rot-2
//             transform: rotate(1deg)
//         .rot
//             transform: rotate(1deg)

//     .rot-1
//         transform: rotate(-2deg)
//         transition: transform 0.5s;

//     .rot-2
//         transition: transform 0.5s;
//         background-image: url(images/Biz-Collage-bridge.jpg)

//     .rot-photo-donate
//         transition: transform 0.5s;
//         background-image: url(images/Biz-Collage-donate.jpg)

//     .rot-photo-voucher
//         transition: transform 0.5s;
//         background-image: url(images/Biz-Collage-voucher.jpg)
