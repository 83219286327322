.featurebox {
    display: flex;
    flex-direction: row;
    padding: 10px;

    transition: transform 0.2s;
    transform: scale(1);

    cursor: pointer;

    color: map-get($colors,'grey-dark');

    &:hover {
        transform: scale(1.02);
        color: map-get($colors,'grey-dark'); }


    .inner {
        @include shadow-md;
        border-radius: 6px;
        width: 100%;

        .img {
            background-color: map-get($colors, 'grey-mid');
            background-size: cover;
            img {
                width: 100%;
                height: auto; } }

        .title {
            text-align: center;
            min-height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center; }

        .icon {
            width: 20px;
            height: 20px; } } }
