header {
    display: flex;
    flex-direction: column;


    svg.logo {
        width: auto;
        height: 84px;
        transition: 0.2s height;
        display: block;
        @include below-wi(mobile) {
            height: 62px; } }



    .cover {
        position: fixed;
        z-index: 997;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba( map-get($colors,'white'),0); } }

.bar {
    background-color: rgba(0,0,0,0);
    transition: 0.4s background;
    color: #ff0;
    background-image: linear-gradient(180deg, rgba(57,57,57,0.00) 50%, rgba(57,57,57,0.00) 100%);

    button {
        color: map-get($colors, 'white');
        cursor: pointer;

        &:hover {
            opacity: 0.6; } } }




.stickey {
    position: relative;
    top: 0px;
    z-index: 999; }

.stickey.stuck, header[menu=open]>.stickey {
        >* {
            position: fixed; }

        .bar {
            background-color: map-get($colors, 'white');
            background-image: linear-gradient(180deg, rgba(57,57,57,0.00) 50%, rgba(57,57,57,0.05) 100%); }

        button {
            color: map-get($colors, 'grey-link'); }
        svg.logo {
            height: 40px; }

        .container-wide.flex-y-center {
            align-items: flex-start; } }


.skyline {
    background-image: url(../../images/cityscape.png), linear-gradient(0deg, rgba(147,159,37,1) 0%, rgba(185,202,40,1) 25%, rgba(185,202,40,1) 75%, rgba(160,174,37,1) 100%);
    background-size: auto 120px, 100% calc(100% - 30px);
    background-repeat: no-repeat;
    background-position: center bottom, top;
    margin-bottom: -$nebenanHeaderOverlap !important;
    min-height: 240px !important;
    @include below-wi(mobile) {
        margin-bottom: -$nebenanHeaderOverlapMobile !important; }

    .wrapper {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding-bottom: $nebenanHeaderOverlap;
        @include below-wi(mobile) {
            padding-bottom: $nebenanHeaderOverlapMobile; } } }


.subpage {
    background-image: url(../../images/cityscape.png), linear-gradient(0deg, rgba(147,159,37,1) 0%, rgba(185,202,40,1) 25%, rgba(185,202,40,1) 75%, rgba(160,174,37,1) 100%);
    background-size: auto 120px, 100% calc(100% - 30px);
    background-repeat: no-repeat;
    background-position: center bottom, top;
    margin-bottom: 0px;
    min-height: 240px !important;

    .wrapper {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding-bottom: 0px; } }




.header-menu {
    position: fixed;
    top: 0px;
    width: 100%;
    background-color: map-get($colors, 'white');
    z-index: 998;
    padding-top: 60px;
    transform: translateY(-100%);
    transition: transform 0.3s;

    li {
        text-align: right;
        border-bottom: 1px solid map-get($colors,'grey-light');
        padding: 18px 0px;

        &.external a {
            &:after {
                content: 'XX'; } }


        a {
            width: map-get($containersWidth,container-wide);
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: auto;
            margin-right: auto;
            text-transform: uppercase;
            color: map-get($colors, 'grey-midlight');

            .link-icon {
                margin-left: 0.5rem; }

            &:hover {
                color: map-get($colors, 'grey-light');
                .link-icon {
                    opacity: 0.6; } } } } }

header[menu=open] .header-menu {
    transform: translateY(0); }

