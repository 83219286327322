
.body-wrap {
    padding-bottom: 240px;
    @include below-wi(mobile) {
        padding-bottom: 300px; }

    footer {
        position: absolute;
        bottom: 0px;
        width: 100%; } }


footer .logo {
    display: inline-block; }


.bottom-gradient {
    background-image: linear-gradient(180deg, rgba(57,57,57,0.00) 0%, rgba(57,57,57,0.30) 100%); }

.nebenan-footer-logo {
    width: 160px; }


.share-icon {
    padding: 0px 5px;
    img {
        width: 44px;
        height: auto; } }

footer .share-icon img {
    width: 64px; }

.large-icons .share-icon img {
    width: 64px; }


.navbar-nav {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    li {
        display: inline-block;
        padding: 0px 10px;
        a {
            color: map-get($colors,'white');
            &:hover {
                color: rgba(map-get($colors,'white'),0.5 ); } } } }
