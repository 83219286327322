
//weights
.text-regular {
    font-weight: 400; }

.text-semibold {
    font-weight: 600; }

.text-bold {
    font-weight: 700; }

//transform
.text-uppercase {
    text-transform: uppercase; }

//align
.text-center {
    text-align: center; }
.text-left {
    text-align: left; }
.text-right {
    text-align: right; }
@include below-wi(mobile) {
    .m-text-center {
        text-align: center; }
    .m-text-left {
        text-align: left; }
    .m-text-right {
        text-align: right; } }

//color
@each $col, $val in $colors {
    .text-color-#{$col} {
        color: $val; } }

//size
@each $key, $size in $textSizes {
    //paddings
    .text-#{$key} {
        font-size: #{$size}; } }
//size
@each $key, $size in $textSizes {
    //paddings
    @include below-wi(mobile) {
        .m-text-#{$key} {
            font-size: #{$size}; } } }

