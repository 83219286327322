
@import 'styles/toolbox/index';
@import 'styles/home';


.klaro .cookie-modal-notice {
  background-color: #0090a1 !important;
  padding: 30px 25px !important;
  max-width: 640px !important; }

.klaro .cookie-modal .cm-modal.cm-klaro {
  background-color: #0090a1 !important;
  padding: 30px 25px !important;
  max-width: 640px !important;

  .cm-header {
    padding: 0 0 1rem 0;
    border-color: #fff !important; }

  .cm-body {
    padding: 1rem 0 1rem 0; }

  .cm-footer {
    padding: 1rem 0 0 0;
    border-color: #fff !important; }

  .cm-required {
    color: #005d68 !important; } }


.klaro .cookie-modal .cm-btn, .klaro .context-notice .cm-btn, .klaro .cookie-notice .cm-btn {
  background-color: #fff !important;
  color: #0090a1 !important;
  text-transform: uppercase;
  padding: 10px 20px !important; }

.klaro a {
  color: #fff !important;
  font-weight: normal !important;
  text-decoration: underline !important; }

.klaro .klaro-p {
  margin: 0 !important;
  padding-bottom: 10px !important; }


.klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider, .klaro .context-notice .cm-list-input.required:checked + .cm-list-label .slider, .klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider {
  background-color: #444 !important;
  &::before {
    background-color: #989898 !important; } }

.klaro .cookie-modal .cm-list-label .slider, .klaro .context-notice .cm-list-label .slider, .klaro .cookie-notice .cm-list-label .slider {
  background-color: #c0c0c0 !important;
  &::before {
    background-color: #ffffff !important; } }

.klaro .cookie-modal .slider, .klaro .context-notice .slider, .klaro .cookie-notice .slider {
  box-shadow: none !important; }

.klaro .cm-btn.cm-btn-danger.cn-decline {
  background-color: transparent !important;
  border: 1px solid #fff !important;
  color: #fff !important; }

.klaro {
  #service-item-core-description, #service-item-gtm-description {
    .cm-list-description, .purposes {
      color: #333 !important; } } }
