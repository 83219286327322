
section, footer, header {

    &.inner-shadow-top {
        position: relative;
        overflow: hidden;
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 70px;
            background-color: red;
            top: -70px;
            @include shadow-md; } }

    &.m-inner-shadow-hide:before {
        display: none; }


    &.inner-shadow-bottom {
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 70px;
            background-color: red;
            top: calc(100% + 10px);
            @include shadow-md; } } }


.parallax {
    min-height: 300px;
    background-color: mag-get($colors,'grey-mid');
    overflow: hidden;
    img {
        position: absolute;
        width: 100%;
        height: auto;
        min-height: 130%;
        object-fit: cover; } }
