

//shadows
@mixin shadow-sm {
    box-shadow: 0 2px 15px 0 rgba(0,0,0,0.30); }

@mixin shadow-md {
    box-shadow: 0 2px 30px 0 rgba(0,0,0,0.30); }

@mixin shadow-lg {
    box-shadow: 0 2px 50px 0 rgba(0,0,0,0.30); }

.shadow-sm {
    @include shadow-sm; }

.shadow-md {
    @include shadow-md; }

.shadow-lg {
    @include shadow-lg; }
