.foldout {

    .badge {
        min-width: 65px;
        position: relative;
        .badge-image {
            position: absolute;
            width: 50px;
            height: 50px;
            top: 0px;
            left: 0px;
            transition: opacity 0.6s;
            opacity: 1;

            &.open {
                opacity: 0; } } }

    &[data-isover=yes] .badge img {
        transform: scale(1.2); }


    &[data-isopen=yes] {
        .cover {
            bottom: -50px !important; }
        .badge .badge-image {
            transform: scale(1.2);
            opacity: 0;
            &.open {
                opacity: 1; } }
        .content {
            pointer-events: all; } }

    @include below-wi(mobile) {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px; }


    .content {
        border-bottom: 1px solid map-get($colors,'grey-light');
        padding-top: 5px;
        color: map-get($colors,'grey-mid');
        pointer-events: none;
        position: relative;

        .toggle {
            pointer-events: all;
            cursor: pointer;
            top: 0px;
            right: 0px; }

        .child {
            overflow: hidden;
            height: 80px;
            position: relative;
            transition: height 0.5s;

            .cover {
                position: absolute;
                width: 100%;
                height: 50px;
                background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                bottom: 0px; } }


        p {
            margin-top: 20px;
            margin-bottom: 20px;
            &:first-child {
                margin-top: 0px; } }


        b {
            color: map-get($colors,'grey-dark'); } }



    ol {
        counter-reset: counter_a;
        list-style: none;
        position: relative;
        padding-left: 30px;

        li {
            counter-increment: counter_a;
            padding-left: 0px;
            font-size: 92%;

            &:before {
                content: '0' counter(counter_a);
                position: absolute;
                left: 0px;
                font-weight: 700;
                color: map-get($colors,'grey-dark'); } } } }

