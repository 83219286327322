@each $key, $size in $containersWidth {
    .#{$key} {
        width: $size;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%; } }


@each $size in $elementWidths {
    .wi-#{$size} {
        width: #{$size + 0%}; } }

@each $size in $elementHeights {
    .hi-#{$size} {
        height: #{$size + 0%}; } }

@include below-wi(mobile) {
    @each $size in $elementWidths {
        .m-wi-#{$size} {
            width: #{$size + 0%}; } }

    @each $size in $elementHeights {
        .m-hi-#{$size} {
            height: #{$size + 0%}; } } }



@each $key, $size in $layoutSizes {
    //paddings
    .p-#{$key} {
        padding: #{$size}; }
    .py-#{$key} {
        padding-top: #{$size};
        padding-bottom: #{$size}; }
    .px-#{$key} {
        padding-left: #{$size};
        padding-right: #{$size}; }
    .pl-#{$key} {
        padding-left: #{$size}; }
    .pr-#{$key} {
        padding-right: #{$size}; }
    .pt-#{$key} {
        padding-top: #{$size}; }
    .pb-#{$key} {
        padding-bottom: #{$size}; }

    //margins
    .m-#{$key} {
        padding: #{$size}; }
    .my-#{$key} {
        padding-top: #{$size};
        padding-bottom: #{$size}; }
    .mx-#{$key} {
        padding-left: #{$size};
        padding-right: #{$size}; }
    .ml-#{$key} {
        padding-left: #{$size}; }
    .mr-#{$key} {
        padding-right: #{$size}; }
    .mt-#{$key} {
        padding-top: #{$size}; }
    .mb-#{$key} {
        padding-bottom: #{$size}; } }

@each $key, $size in $layoutSizes {
    @include below-wi(mobile) {
        //paddings
        .m-p-#{$key} {
            padding: #{$size}; }
        .m-py-#{$key} {
            padding-top: #{$size};
            padding-bottom: #{$size}; }
        .m-px-#{$key} {
            padding-left: #{$size};
            padding-right: #{$size}; }
        .m-pl-#{$key} {
            padding-left: #{$size}; }
        .m-pr-#{$key} {
            padding-right: #{$size}; }
        .m-pt-#{$key} {
            padding-top: #{$size}; }
        .m-pb-#{$key} {
            padding-bottom: #{$size}; }

        //margins
        .m-m-#{$key} {
            padding: #{$size}; }
        .m-my-#{$key} {
            padding-top: #{$size};
            padding-bottom: #{$size}; }
        .m-mx-#{$key} {
            padding-left: #{$size};
            padding-right: #{$size}; }
        .m-ml-#{$key} {
            padding-left: #{$size}; }
        .m-mr-#{$key} {
            padding-right: #{$size}; }
        .m-mt-#{$key} {
            padding-top: #{$size}; }
        .m-mb-#{$key} {
            padding-bottom: #{$size}; } } }
